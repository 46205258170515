var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cap-table"
  }, [_c('div', {
    staticClass: "cap-table__inner"
  }, [_c('b-table', _vm._g(_vm._b({
    ref: "capTable",
    staticClass: "sygni-b-table",
    attrs: {
      "fields": _vm.tableFields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "no-local-sorting": true,
      "per-page": _vm.perPage,
      "busy": _vm.isBusy,
      "show-empty": ""
    },
    on: {
      "sort-changed": _vm.onSortChange,
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "row-clicked": _vm.onRowClicked
    },
    scopedSlots: _vm._u([{
      key: "thead-top",
      fn: function fn() {
        return [_c('tr', {
          staticClass: "thead-top"
        }, [_c('th'), _c('th', {
          attrs: {
            "colspan": "2",
            "variant": "secondary"
          }
        }, [_vm._v("Founders")]), _c('th', {
          attrs: {
            "colspan": "2",
            "variant": "secondary"
          }
        }, [_vm._v("Investors")]), _c('th', {
          attrs: {
            "colspan": "2",
            "variant": "secondary"
          }
        }, [_vm._v("Options")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "colspan": "3",
            "variant": "secondary"
          }
        }, [_vm._v("Total fully dilluted")])])];
      },
      proxy: true
    }, {
      key: "empty",
      fn: function fn() {
        return [_c('div', {
          staticClass: "cell cell--empty"
        }, [_vm._v("No shareholders")])];
      },
      proxy: true
    }, {
      key: "head(name)",
      fn: function fn() {
        return [_c('div', {
          staticClass: "shareholders"
        }, [_vm._v("Shareholders")])];
      },
      proxy: true
    }, {
      key: "head(totalPercentage)",
      fn: function fn() {
        return [_c('div', [_vm._v("Percent "), _c('br'), _vm._v(" Structure")])];
      },
      proxy: true
    }, _vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "cell(name)",
      fn: function fn(rowData) {
        return [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: _vm.getTooltipMessage(rowData.item.name),
            expression: "getTooltipMessage(rowData.item.name)",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "cell",
          on: {
            "click": function click($event) {
              return _vm.openDetails(rowData);
            }
          }
        }, [_vm._v(_vm._s(_vm._f("trimString")(rowData.item.name, 42)))])];
      }
    }, {
      key: "cell(founders.numberOfShares)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell text-nowrap text-right",
          on: {
            "click": function click($event) {
              return _vm.openDetails(rowData);
            }
          }
        }, [_vm._v(_vm._s(_vm._f("thousandSeparator")(rowData.item.founders.numberOfShares)))])];
      }
    }, {
      key: "cell(founders.value)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell text-nowrap text-right",
          on: {
            "click": function click($event) {
              return _vm.openDetails(rowData);
            }
          }
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(rowData.item.founders.value, 2)))])];
      }
    }, {
      key: "cell(investors.numberOfShares)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell text-nowrap text-right",
          on: {
            "click": function click($event) {
              return _vm.openDetails(rowData);
            }
          }
        }, [_vm._v(_vm._s(_vm._f("thousandSeparator")(rowData.item.investors.numberOfShares)))])];
      }
    }, {
      key: "cell(investors.value)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell text-nowrap text-right",
          on: {
            "click": function click($event) {
              return _vm.openDetails(rowData);
            }
          }
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(rowData.item.investors.value, 2)))])];
      }
    }, {
      key: "cell(options.numberOfShares)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell text-nowrap text-right",
          on: {
            "click": function click($event) {
              return _vm.openDetails(rowData);
            }
          }
        }, [_vm._v(_vm._s(_vm._f("thousandSeparator")(rowData.item.options.numberOfShares)))])];
      }
    }, {
      key: "cell(options.value)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell text-nowrap text-right",
          on: {
            "click": function click($event) {
              return _vm.openDetails(rowData);
            }
          }
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(rowData.item.options.value, 2)))])];
      }
    }, {
      key: "cell(totals.numberOfShares)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell text-nowrap text-right",
          on: {
            "click": function click($event) {
              return _vm.openDetails(rowData);
            }
          }
        }, [_vm._v(_vm._s(_vm._f("thousandSeparator")(rowData.item.totals.numberOfShares)))])];
      }
    }, {
      key: "cell(totals.value)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell text-nowrap text-right",
          on: {
            "click": function click($event) {
              return _vm.openDetails(rowData);
            }
          }
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(rowData.item.totals.value, 2)))])];
      }
    }, {
      key: "cell(totalPercentage)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell",
          on: {
            "click": function click($event) {
              return _vm.openDetails(rowData);
            }
          }
        }, [_c('div', {
          staticClass: "table__arrow d-flex justify-content-end align-items-center pl-4 pr-4 text-right"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm.getTotalPercentage(rowData.item.totals.value)))]), _c('div', {
          staticClass: "arrow"
        }, [_c('sygni-arrow-button', {
          attrs: {
            "open": rowData.item._showDetails,
            "rotate-on-click": true
          }
        })], 1)])])];
      }
    }, {
      key: "row-details",
      fn: function fn(rowData) {
        return [_c('cap-inner-table', {
          attrs: {
            "items": rowData.item.details
          }
        })];
      }
    }, {
      key: "top-row",
      fn: function fn() {
        var _vm$shareholderTotals, _vm$shareholderTotals2, _vm$shareholderTotals3, _vm$shareholderTotals4, _vm$shareholderTotals5, _vm$shareholderTotals6, _vm$shareholderTotals7, _vm$shareholderTotals8, _vm$shareholderTotals9, _vm$shareholderTotals10, _vm$shareholderTotals11, _vm$shareholderTotals12, _vm$shareholderTotals13, _vm$shareholderTotals14, _vm$shareholderTotals15, _vm$shareholderTotals16, _vm$shareholderTotals17, _vm$shareholderTotals18;

        return [_c('td', {
          staticClass: "text-left"
        }, [_vm._v("TOTAL:")]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("thousandSeparator")(((_vm$shareholderTotals = _vm.shareholderTotals) === null || _vm$shareholderTotals === void 0 ? void 0 : (_vm$shareholderTotals2 = _vm$shareholderTotals.founders) === null || _vm$shareholderTotals2 === void 0 ? void 0 : _vm$shareholderTotals2.numberOfShares) || 0)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")((_vm$shareholderTotals3 = _vm.shareholderTotals) === null || _vm$shareholderTotals3 === void 0 ? void 0 : (_vm$shareholderTotals4 = _vm$shareholderTotals3.founders) === null || _vm$shareholderTotals4 === void 0 ? void 0 : _vm$shareholderTotals4.value, 2)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("thousandSeparator")(((_vm$shareholderTotals5 = _vm.shareholderTotals) === null || _vm$shareholderTotals5 === void 0 ? void 0 : (_vm$shareholderTotals6 = _vm$shareholderTotals5.investors) === null || _vm$shareholderTotals6 === void 0 ? void 0 : _vm$shareholderTotals6.numberOfShares) || 0)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")((_vm$shareholderTotals7 = _vm.shareholderTotals) === null || _vm$shareholderTotals7 === void 0 ? void 0 : (_vm$shareholderTotals8 = _vm$shareholderTotals7.investors) === null || _vm$shareholderTotals8 === void 0 ? void 0 : _vm$shareholderTotals8.value, 2)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("thousandSeparator")(((_vm$shareholderTotals9 = _vm.shareholderTotals) === null || _vm$shareholderTotals9 === void 0 ? void 0 : (_vm$shareholderTotals10 = _vm$shareholderTotals9.options) === null || _vm$shareholderTotals10 === void 0 ? void 0 : _vm$shareholderTotals10.numberOfShares) || 0)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")((_vm$shareholderTotals11 = _vm.shareholderTotals) === null || _vm$shareholderTotals11 === void 0 ? void 0 : (_vm$shareholderTotals12 = _vm$shareholderTotals11.options) === null || _vm$shareholderTotals12 === void 0 ? void 0 : _vm$shareholderTotals12.value, 2)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("thousandSeparator")(((_vm$shareholderTotals13 = _vm.shareholderTotals) === null || _vm$shareholderTotals13 === void 0 ? void 0 : (_vm$shareholderTotals14 = _vm$shareholderTotals13.totals) === null || _vm$shareholderTotals14 === void 0 ? void 0 : _vm$shareholderTotals14.numberOfShares) || 0)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")((_vm$shareholderTotals15 = _vm.shareholderTotals) === null || _vm$shareholderTotals15 === void 0 ? void 0 : (_vm$shareholderTotals16 = _vm$shareholderTotals15.totals) === null || _vm$shareholderTotals16 === void 0 ? void 0 : _vm$shareholderTotals16.value, 2)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm.getTotalPercentage((_vm$shareholderTotals17 = _vm.shareholderTotals) === null || _vm$shareholderTotals17 === void 0 ? void 0 : (_vm$shareholderTotals18 = _vm$shareholderTotals17.totals) === null || _vm$shareholderTotals18 === void 0 ? void 0 : _vm$shareholderTotals18.value)))])];
      },
      proxy: true
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners)), _c('b-table', _vm._g(_vm._b({
    ref: "capTable2",
    staticClass: "sygni-b-table cap-table__options-table",
    attrs: {
      "items": _vm.tableOptions,
      "fields": _vm.tableFields,
      "show-empty": ""
    },
    on: {
      "sort-changed": _vm.onSortChange
    },
    scopedSlots: _vm._u([{
      key: "thead-top",
      fn: function fn() {
        return [_c('tr', {
          staticClass: "thead-top"
        }, [_c('th'), _c('th', {
          attrs: {
            "colspan": "2",
            "variant": "secondary"
          }
        }, [_vm._v("Founders")]), _c('th', {
          attrs: {
            "colspan": "2",
            "variant": "secondary"
          }
        }, [_vm._v("Investors")]), _c('th', {
          attrs: {
            "colspan": "2",
            "variant": "secondary"
          }
        }, [_vm._v("Options")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "colspan": "3",
            "variant": "secondary"
          }
        }, [_vm._v("Total fully dilluted")])])];
      },
      proxy: true
    }, {
      key: "empty",
      fn: function fn() {
        return [_c('div', {
          staticClass: "cell cell--empty"
        }, [_vm._v("No options")])];
      },
      proxy: true
    }, {
      key: "top-row",
      fn: function fn() {
        var _vm$optionsStats, _vm$optionsStats$foun, _vm$optionsStats2, _vm$optionsStats2$fou, _vm$optionsStats3, _vm$optionsStats3$inv, _vm$optionsStats4, _vm$optionsStats4$inv, _vm$optionsStats5, _vm$optionsStats5$opt, _vm$optionsStats6, _vm$optionsStats6$opt, _vm$optionsStats7, _vm$optionsStats7$tot, _vm$optionsStats8, _vm$optionsStats8$tot, _vm$optionsStats9, _vm$optionsStats9$tot;

        return [_c('td', {
          staticClass: "text-left"
        }, [_vm._v("TOTAL:")]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("thousandSeparator")(((_vm$optionsStats = _vm.optionsStats) === null || _vm$optionsStats === void 0 ? void 0 : (_vm$optionsStats$foun = _vm$optionsStats.founders) === null || _vm$optionsStats$foun === void 0 ? void 0 : _vm$optionsStats$foun.numberOfShares) || 0)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")((_vm$optionsStats2 = _vm.optionsStats) === null || _vm$optionsStats2 === void 0 ? void 0 : (_vm$optionsStats2$fou = _vm$optionsStats2.founders) === null || _vm$optionsStats2$fou === void 0 ? void 0 : _vm$optionsStats2$fou.value, 2)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("thousandSeparator")(((_vm$optionsStats3 = _vm.optionsStats) === null || _vm$optionsStats3 === void 0 ? void 0 : (_vm$optionsStats3$inv = _vm$optionsStats3.investors) === null || _vm$optionsStats3$inv === void 0 ? void 0 : _vm$optionsStats3$inv.numberOfShares) || 0)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")((_vm$optionsStats4 = _vm.optionsStats) === null || _vm$optionsStats4 === void 0 ? void 0 : (_vm$optionsStats4$inv = _vm$optionsStats4.investors) === null || _vm$optionsStats4$inv === void 0 ? void 0 : _vm$optionsStats4$inv.value, 2)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("thousandSeparator")(((_vm$optionsStats5 = _vm.optionsStats) === null || _vm$optionsStats5 === void 0 ? void 0 : (_vm$optionsStats5$opt = _vm$optionsStats5.options) === null || _vm$optionsStats5$opt === void 0 ? void 0 : _vm$optionsStats5$opt.numberOfShares) || 0)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")((_vm$optionsStats6 = _vm.optionsStats) === null || _vm$optionsStats6 === void 0 ? void 0 : (_vm$optionsStats6$opt = _vm$optionsStats6.options) === null || _vm$optionsStats6$opt === void 0 ? void 0 : _vm$optionsStats6$opt.value, 2)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("thousandSeparator")(((_vm$optionsStats7 = _vm.optionsStats) === null || _vm$optionsStats7 === void 0 ? void 0 : (_vm$optionsStats7$tot = _vm$optionsStats7.totals) === null || _vm$optionsStats7$tot === void 0 ? void 0 : _vm$optionsStats7$tot.numberOfShares) || 0)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")((_vm$optionsStats8 = _vm.optionsStats) === null || _vm$optionsStats8 === void 0 ? void 0 : (_vm$optionsStats8$tot = _vm$optionsStats8.totals) === null || _vm$optionsStats8$tot === void 0 ? void 0 : _vm$optionsStats8$tot.value, 2)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm.getTotalPercentage((_vm$optionsStats9 = _vm.optionsStats) === null || _vm$optionsStats9 === void 0 ? void 0 : (_vm$optionsStats9$tot = _vm$optionsStats9.totals) === null || _vm$optionsStats9$tot === void 0 ? void 0 : _vm$optionsStats9$tot.value)))])];
      },
      proxy: true
    }, {
      key: "head(name)",
      fn: function fn() {
        return [_c('div', {
          staticClass: "shareholders"
        }, [_vm._v("Options")])];
      },
      proxy: true
    }, {
      key: "head(totalPercentage)",
      fn: function fn() {
        return [_c('div', [_vm._v("Percent "), _c('br'), _vm._v(" Structure")])];
      },
      proxy: true
    }, _vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "cell(name)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell"
        }, [_vm._v(_vm._s(rowData.item.name))])];
      }
    }, {
      key: "cell(founders.numberOfShares)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("thousandSeparator")(rowData.item.founders.numberOfShares || 0)))])];
      }
    }, {
      key: "cell(founders.value)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(rowData.item.founders.value, 2)))])];
      }
    }, {
      key: "cell(investors.numberOfShares)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("thousandSeparator")(rowData.item.investors.numberOfShares || 0)))])];
      }
    }, {
      key: "cell(investors.value)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(rowData.item.investors.value, 2)))])];
      }
    }, {
      key: "cell(options.numberOfShares)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("thousandSeparator")(rowData.item.options.numberOfShares || 0)))])];
      }
    }, {
      key: "cell(options.value)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(rowData.item.options.value, 2)))])];
      }
    }, {
      key: "cell(totals.numberOfShares)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("thousandSeparator")(rowData.item.totals.numberOfShares || 0)))])];
      }
    }, {
      key: "cell(totals.value)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(rowData.item.totals.value, 2)))])];
      }
    }, {
      key: "cell(totalPercentage)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell"
        }, [_c('div', {
          staticClass: "table__arrow d-flex justify-content-end align-items-center pl-4 pr-4 text-right"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm.getTotalPercentage(rowData.item.totals.value)))]), _c('div', {
          staticClass: "arrow"
        }, [_c('sygni-arrow-button', {
          attrs: {
            "rotate-on-click": true
          }
        })], 1)])])];
      }
    }, {
      key: "bottom-row",
      fn: function fn() {
        return [_c('td', {
          staticClass: "text-left"
        }, [_vm._v("TOTAL:")]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("thousandSeparator")(_vm.totals.founders.numberOfShares || 0)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.totals.founders.value, 2)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("thousandSeparator")(_vm.totals.investors.numberOfShares || 0)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.totals.investors.value, 2)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("thousandSeparator")(_vm.totals.options.numberOfShares || 0)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.totals.options.value, 2)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("thousandSeparator")(_vm.totals.totals.numberOfShares || 0)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.totals.totals.value, 2)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm.getTotalPercentage(_vm.totals.totals.value)))])];
      },
      proxy: true
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners)), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isBusy,
      expression: "isBusy"
    }],
    staticClass: "text-center spinner"
  }, [_c('b-spinner', {
    staticClass: "align-middle mr-3"
  }), _c('strong', [_vm._v("Loading...")])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }